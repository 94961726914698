import { useAuthStore } from '@/stores/auth';
import { useToast } from 'vue-toastification';

const toast = useToast();

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
};

function request(method) {
  return async (url, body, withFiles) => {
    const requestOptions = {
      method,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    };
    if (body) {
      if (!withFiles) requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = !withFiles ? JSON.stringify(body) : body;
    }

    return fetch(url, requestOptions).then(handleResponse);
  };
}

// helper functions

function handleResponse(response) {
  const authStore = useAuthStore();

  return response.text().then(async (text) => {
    const data = text && JSON.parse(text.toString());

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        authStore.logoutCallback();
      }
      let messages = null;
      if (data?.messages) messages = Object.values(data.messages).join('\n');

      let error = data?.message || messages || response.statusText;

      if (error === 'Unauthorized') {
        error = 'Acesso Negado';
      }

      toast.error(error.toString());
      return Promise.reject(error);
    }

    return data;
  });
}
