import { defineStore } from 'pinia';
import authService from '@/services/auth';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

let refreshTokenTimeout = null;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: getToken() ? jwt_decode(getToken()) : null,
    socketBooted: false,
    logoutByLOAMessage: getLogoutByLOAMessage(),
  }),
  actions: {
    // level (nível de garantia): baixo, substancial, alto
    async login(level = '') {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
        level,
      });

      window.location.href = (await this.logoutByLOA())
        ? window.location.origin
        : `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    async verifyLogin() {
      if (!this.user) return this.login();
      await this.verifyAndUpdateToken();
      if (!this.user) return this.login();
      return this.user;
    },
    async logout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      window.location.href = `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    urlLogin() {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
      });

      return this.logoutByLOA()
        ? location.origin
        : `${import.meta.env.VITE_API_URL}/login?${params.toString()}`;
    },
    urlLogout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      return `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    logoutCallback() {
      this.user = null;
      Cookies.remove('application-token');
      localStorage.removeItem('cartorio');
    },
    logoutByLOA() {
      return !!Cookies.get('logout_by_LOA');
    },
    updateToken() {
      this.user = jwt_decode(getToken());
    },
    async verifyAndUpdateToken() {
      try {
        await authService.verifyAndUpdateToken();
        this.user = jwt_decode(getToken());
        this.refreshToken();
        this.updateToken();
      } catch (e) {
        await this.logoutCallback();
      }
    },
    refreshToken() {
      clearTimeout(refreshTokenTimeout);
      const currentDate = new Date();
      const expiresAt = new Date(0);
      expiresAt.setUTCSeconds(this.user.expires_at);
      const executeAt = expiresAt - currentDate - 60;

      refreshTokenTimeout = setTimeout(async () => {
        await this.verifyAndUpdateToken();
      }, executeAt);
    },
    async ensureLOA(level) {
      if (level === 'substancial' && this.user.acr === 'urn:idrc:loa:baixo') {
        return this.login(level);
      }

      if (level === 'alto' && this.user.acr !== 'urn:idrc:loa:alto') {
        return this.login(level);
      }
    },
    clearLOAMessage() {
      Cookies.remove('logout_by_LOA');
      this.logoutByLOAMessage = false;
    },
    getToken() {
      const token = Cookies.get('application-token');
      return token && JSON.parse(atob(token)).message;
    },
  },
});

function getToken() {
  try {
    const token = Cookies.get('application-token');
    return token && JSON.parse(atob(token)).message;
  } catch (e) {
    console.log(e);
  }
}

function getLogoutByLOAMessage() {
  try {
    const token = Cookies.get('logout_by_LOA');
    return token && decodeURIComponent(JSON.parse(atob(token)).message);
  } catch (e) {
    console.error(e);
  }
}