import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth'; 

const adminRoutes = [];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/idrc',
      name: 'idrc',
      component: () => import('../views/IdRCView.vue'),
    },
    {
      path: '/idrc-sucesso',
      name: 'idrc-sucesso',
      component: () => import('../views/IdRCSucessoView.vue'),
    },
    {
      path: '/bootstrap',
      name: 'bootstrap',
      component: () => import('../views/BootstrapView.vue'),
    },
    {
      path: '/',
      name: 'pesquisar-solicitacoes',
      component: () => import('../views/PesquisarSolicitacoesView.vue'),
    },
    {
      path: '/acervo-recolhido',
      name: 'pesquisar-solicitacoes-acervo-recolhido',
      component: () => import('../views/PesquisarSolicitacoesAcervoRecolhidoView.vue'),
    },
    {
      path: '/projetos',
      name: 'projetos',
      component: () => import('../views/ProjetosView.vue'),
    },
    {
      path: '/solicitacoes',
      name: 'solicitacoes',
      component: () => import('../views/SolicitacoesView.vue'),
    },
    {
      path: '/solicitacoes-acervo-recolhido',
      name: 'solicitacoes-acervo-recolhido',
      component: () => import('../views/SolicitacoesAcervoRecolhidoView.vue'),
    },
    {
      path: '/editar-cota/:id',
      name: 'editar-cota',
      component: () => import('../views/EditarCotaView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/editar-cota-acervo-recolhido/:id',
      name: 'editar-cota-acervo-recolhido',
      component: () => import('../views/EditarCotaAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/editar-cota-complementar/:id',
      name: 'editar-cota-complementar',
      component: () => import('../views/EditarCotaComplementarView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/editar-cota-complementar-acervo-recolhido/:id',
      name: 'editar-cota-complementar-acervo-recolhido',
      component: () => import('../views/EditarCotaComplementarAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/complementar/:id',
      name: 'complementar',
      component: () => import('../views/PagamentoComplementarView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/complementar-acervo-recolhido/:id',
      name: 'complementar-acervo-recolhido',
      component: () => import('../views/PagamentoComplementarAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/obter-cota-complementar/:id',
      name: 'obter-cota-complementar',
      component: () => import('../views/CotaComplementarView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/obter-cota-complementar-acervo-recolhido/:id',
      name: 'obter-cota-complementar-acervo-recolhido',
      component: () => import('../views/CotaComplementarAcervoRecolhidoView.vue'),
      props: {
        default: true,
      },
    },
    {
      path: '/boleto-sucesso',
      name: 'boleto-sucesso',
      component: () => import('../views/BoletoView.vue'),
    },
    {
      path: '/boleto-sucesso-acervo-recolhido',
      name: 'boleto-sucesso-acervo-recolhido',
      component: () => import('../views/BoletoAcervoRecolhidoView.vue'),
    },
    /*{
      path: '/pesquisar-solicitacoes',
      name: 'pesquisar-solicitacoes',
      component: () => import('../views/PesquisarSolicitacoesView.vue'),
    },*/
    {
      path: '/nivel-baixo',
      name: 'nivel-baixo',
      component: () => import('../views/nivelDeGarantia/NivelBaixoView.vue'),
    },
    {
      path: '/nivel-baixo',
      name: 'nivel-baixo',
      component: () => import('../views/nivelDeGarantia/NivelBaixoView.vue'),
    },
    {
      path: '/nivel-substancial',
      name: 'nivel-substancial',
      component: () => import('../views/nivelDeGarantia/NivelSubstancialView.vue'),
    },
    {
      path: '/nivel-alto',
      name: 'nivel-alto',
      component: () => import('../views/nivelDeGarantia/NivelAltoView.vue'),
    },

    ...adminRoutes.map((item) => {
      item.path = '/admin' + item.path;
      return item;
    }),
  ],
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/bootstrap', '/sem-acesso', '/idrc', '/idrc-sucesso'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  const { logout } = to.query;

  if (logout) {
    await auth.logoutCallback();
    return '/';
  } 

  if (auth.user || authRequired) {
    const authenticated = await auth.verifyLogin();

    if (!authenticated) {
      auth.logoutCallback();
      window.location.href = `${import.meta.env.VITE_REDIRECT_URL}`;
    }

    if (to.path === '/nivel-baixo') await auth.ensureLOA('baixo');
    if (to.path === '/nivel-substancial') await auth.ensureLOA('substancial');
    if (to.path === '/nivel-alto') await auth.ensureLOA('alto');

    if (to.path.includes('/admin') && !auth.user.isAdmin) {
      return '/';
    }
  }
});

export default router;
